<template>
  <div v-if="type !== 'hidden'" class="FacetDropdown" :class="{ 'is-open': isOpen }">
    <span v-if="type !== 'toggle'" class="text-primary-1-100 text-sm font-bold">{{
      type === 'price' ? $t('price') : label
    }}</span>
    <div v-if="type === 'toggle'" class="flex justify-between font-bold h-full w-full">
      {{ label }}
      <ToggleInput
        :model-value="Array.isArray(value) && value.length ? value[0] : []"
        :true-value="(options as AggregationOption[])?.find((option: AggregationOption) => option.value === '1')"
        :false-value="[]"
        @input="($event: AggregationOption) => updateFacet($event)"
      />
    </div>

    <GenericMultiselect
      v-if="type === 'select'"
      :model-value="selectedOptions"
      :options="options"
      :immediate="true"
      class="text-sm mt-2"
      identified-by="value"
      @close="toggle(false)"
      @input="updateFacet"
    />
    <PriceFacet
      v-if="type === 'price'"
      class="bg-white"
      :value="selectedOptions"
      :options="options"
      @input="updateFacet"
    />
  </div>
</template>

<script setup lang="ts">
import { AggregationOption } from '@robustastudio/e-commerce/dist/runtime/graphql/Cart';

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  value: {
    type: Object as PropType<AggregationOption[] | undefined | null>,
    default: null,
  },
  options: {
    type: Array,
    default: () => [],
  },
  type: {
    type: String,
    default: 'select',
  },
});
const emit = defineEmits(['input']);

const currencyCode = inject(CONFIG_STORE_CURRENCY);
const { isOpen, toggle } = useDropdown();

const { t: $t } = useI18n({
  useScope: 'local',
});
const selectedOptions = computed(() =>
  Array.isArray(props.value) ? props.value : [props.value].filter(val => val ?? false)
);

const selectedLabel = computed(() => {
  if (props.type !== 'price') {
    return selectedOptions.value.map(curr => curr?.label).join(',');
  }

  // Skip including price if it already has the value of the existing min/max range.
  if (selectedOptions.value.toString() === props.options.toString()) {
    return '';
  }

  if (props.type === 'price' && selectedOptions.value.toString().split(',').length) {
    return `${selectedOptions.value.toString().split(',')[0]} ${currencyCode?.value} - ${
      selectedOptions.value.toString().split(',')[1]
    } ${currencyCode?.value} `;
  }
  return '1';
});

/** * called when a new facet option is selected / deselected * handles emitting the new values to the parent component
(category page) */
function updateFacet(newValue: AggregationOption) {
  emit('input', newValue);
}
</script>

<style lang="postcss" scoped>
.FacetDropdown {
  @apply relative;

  &__toggle {
    @apply w-full flex items-center border-b border-primary-1-15  py-2 text-base;

    &:focus {
      @apply outline-none;
    }

    &-caret {
      transition: transform 0.2s ease-in-out;
    }
  }

  &.is-open {
    .FacetDropdown__toggle {
      @apply border-none;
    }
    .FacetDropdown__toggle-caret {
      transform: rotate(180deg);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
<i18n>
{
  "en": {
    "select": "Choose ",
    "price": "Price"
  },
  "ar": {
    "select": "أختر ...",
    "price": "السعر"
  }
}
</i18n>
