<template>
  <div class="AppAccordion" :class="{ 'is-active': isExpanded }">
    <div class="Summary flex items-center justify-between cursor-pointer" @click="toggle">
      <slot name="summary">
        <span>{{ label }}</span>
      </slot>

      <button type="button" class="h-10 flex items-center justify-center">
        <svg-icon-chevron-down
          class="transform w-4 h-4"
          :class="{
            'rotate-180': isExpanded,
          }"
        />
      </button>
    </div>
    <Transition name="slide">
      <div v-if="isExpanded">
        <slot />
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  modelValue: {
    type: null,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const isExpanded = computed(() => {
  if (props.name) {
    return props.modelValue === props.name;
  }

  return props.modelValue === true;
});

function toggle(force?: boolean) {
  if (typeof force === 'boolean') {
    emit('update:modelValue', props.name || true);
    return;
  }

  emit('update:modelValue', isExpanded.value ? null : props.name || true);
}
</script>

<style lang="postcss" scoped>
.AppAccordion {
  @apply flex flex-col;

  &__title {
    @apply flex items-center;
  }
}

.slide-enter-active {
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave-from {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter-from,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
