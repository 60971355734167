<template>
  <div class="SortProducts">
    <span class="text-primary-1-100 text-sm font-bold">
      {{ $t('sortBy') }}
    </span>

    <GenericMultiselect
      v-model="selectedOption"
      :options="computedOptions"
      class="w-full mt-3 text-sm"
      single
      immediate
      @input="e => (selectedOption = e)"
    />
  </div>
</template>

<script setup lang="ts">
const { t: $t } = useI18n({
  useScope: 'local',
});

const { options, selectedOption } = useSortBy();
const computedOptions = computed(() => {
  return options.value?.map(option => ({
    label: $t(option.label).toString(),
    value: option.value,
  }));
});
</script>

<style lang="postcss" scoped>
.SortProducts {
  @apply relative;
  min-width: 200px;

  &__toggle {
    @apply w-full flex items-center text-sm;

    &:focus {
      @apply outline-none;
    }

    &-caret {
      transition: transform 0.2s ease-in-out;
    }
  }

  &.is-open {
    .SortProducts__toggle-caret {
      transform: rotate(180deg);
    }
  }

  &__MenuItems {
    @apply absolute left-0 right-0 z-20 flex flex-col bg-white;
    top: calc(100% - 2px);
    box-shadow: 0 0 20px 0 rgba(144, 144, 144, 0.15);

    li:not(:first-child) {
      @apply border-t border-primary-200 pt-3;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<i18n>
{
  "en": {
    "sortBy": "Sort by",
    "mostPopular": "Most Popular",
    "priceLowToHigh": "From Low To High",
    "priceHighToLow": "From High To Low"
  },
  "ar": {
    "sortBy": "ترتيب",
    "mostPopular": "الأكثر شيوعا",
    "priceLowToHigh": "حسب السعر من الاقل للأكبر",
    "priceHighToLow": "حسب السعر من الاكبر للأقل"
  }
}
</i18n>
