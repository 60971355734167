<template>
  <div class="PriceFacet">
    <div class="mt-2 grid grid-cols-2 gap-x-12 justify-between">
      <div>
        <span class="text-system-gray text-xs">{{ $t('from') }}</span>
        <div class="flex items-center">
          <span class="bg-transparent" @change="updatePriceRange(0, $event)">
            {{ range[0] }}
          </span>
          <span class="ml-2 text-primary-700">{{ currencyCode }}</span>
        </div>
      </div>

      <div>
        <span class="text-system-gray text-xs">{{ $t('to') }}</span>
        <div class="flex items-center">
          <span class="bg-transparent" @change="updatePriceRange(1, $event)">
            {{ range[1] }}
          </span>
          <span class="ml-2 text-primary-700">{{ currencyCode }}</span>
        </div>
      </div>
    </div>

    <div class="mt-4 pl-2">
      <VueSlider
        v-model="range"
        :min="options[0]"
        :max="options[1]"
        :direction="$i18n.locale === 'en' ? 'ltr' : 'rtl'"
      />
    </div>

    <div v-if="lazy" class="mt-4 grid grid-cols-3 gap-x-3">
      <button class="py-2 border-2 border-primary-700 text-primary-700" @click="cancel">
        {{ $t('cancel') }}
      </button>
      <button class="py-2 bg-primary-700 text-white col-span-2" @click="apply">
        {{ $t('apply') }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { debounce, uniq } from 'lodash-es';
// Lazy load it because it requires access to `document` which
// breaks SSR, so we want to avoid rendering it until the client-side has loaded
// Async components does that for us!
const VueSlider = defineAsyncComponent(() => import('vue-slider-component'));

const props = defineProps({
  value: {
    type: null,
    default: null,
  },
  options: {
    type: Array,
    required: true,
  },
  lazy: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['input', 'close']);

// to protect the slider just remove the duplicated props values
const uniqueValue = uniq(props.value);

const range = ref<[number, number]>(uniqueValue?.length ? ([...uniqueValue] as [number, number]) : [0, 0]);

const currencyCode = inject(CONFIG_STORE_CURRENCY);

function updatePriceRange(valueIdx: 0 | 1, e: InputEvent) {
  const inputValue = Number((e.target as any).value);
  if (Number.isNaN(inputValue)) {
    return;
  }

  const newValue: [number, number] = [...range.value];
  newValue[valueIdx] = inputValue;
  range.value = newValue;
}

const { t: $t } = useI18n({
  useScope: 'local',
});

if (!props.lazy) {
  watch(range, debounce(apply, 300));
}

function apply() {
  emit('input', range.value);
  emit('close');
}

function cancel() {
  range.value = [...props.value] as typeof range.value;
  emit('close');
}
</script>

<style lang="postcss" scoped>
.PriceFacet {
  @apply flex flex-col;

  /* https://vue-loader.vuejs.org/guide/scoped-css.html#deep-selectors */
  :deep() {
    .vue-slider-dot {
      @apply w-5 h-5 rounded-full bg-primary-1-100;
    }
    .vue-slider-dot-handle {
      @apply bg-primary-1-100;
    }

    .vue-slider-process {
      @apply bg-primary-1-100;
    }

    .vue-slider-dot-tooltip-inner-top {
      @apply bg-primary-1-100 border-primary-1-100;
    }

    .vue-slider-rail {
      @apply rounded-none bg-primary-1-05;
      height: 2px;
    }

    .vue-slider-dot-tooltip-text {
      @apply text-white p-2;
    }
  }

  input {
    @apply text-primary-1-100 text-base;
    appearance: textfield;
    min-height: 100%;
    text-align: right;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &__Currency {
    /* Fits at least 3 characters */
    min-width: 3em;
  }
}
</style>

<i18n>
{
  "en": {
    "cancel": "Cancel",
    "apply": "Apply",
    "from": "Min.",
    "to": "Max."
  },
  "ar": {
    "cancel": "اغلاق",
    "apply": "اختيار",
    "from": "من",
    "to": "الى"
  }
}
</i18n>
